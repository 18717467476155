import { number } from 'echarts/src/export'
import { submitReportHealth } from '@/js/axios'
import { isPhone, isHKPhone, timesStr } from './utils'

import { Toast } from 'vant' //vant-UI的提示組件

import dayjs from 'dayjs'

import { Base64 } from 'js-base64'
import { isAgeValid } from './data'

export default {
  name: 'question2',
  data() {
    return {
      progressNum: 0,
      questionNum: 20,
      currentIndex: 0,
      pageIndex: 1,
      markShowNull: false,
      markShowError: false,
      radio: '',
      result: ['a', 'b'],
      swipeHeight: 0,
      errorTip: '',
      successShow: false,
      username: '',
      areaList: [
        { text: '江蘇省', code: '3200' },
        { text: '上海市', code: '3100' },
        { text: '北京市', code: '1100' },
        { text: '天津市', code: '1200' },
        { text: '浙江省', code: '3300' },
        { text: '廣東省', code: '4400' },
        { text: '遼寧省', code: '2100' },
        { text: '山東省', code: '3700' },
        { text: '內蒙古自治區', code: '1500' },
        { text: '重慶市', code: '5000' },
        { text: '福建省', code: '3500' },
        { text: '海南省', code: '4600' },
        { text: '湖北省', code: '4200' },
        { text: '湖南省', code: '4300' },
        { text: '陝西省', code: '6100' },
        { text: '河南省', code: '4100' },
        { text: '黑龍江省', code: '2300' },
        { text: '青海省', code: '6300' },
        { text: '雲南省', code: '5300' },
        { text: '安徽省', code: '3400' },
        { text: '四川省', code: '5100' },
        { text: '貴州省', code: '5200' },
        { text: '甘肅省', code: '6200' },
        { text: '江西省', code: '3600' },
        { text: '吉林省', code: '2200' },
        { text: '山西省', code: '1400' },
        { text: '河北省', code: '1300' },
        { text: '寧夏回族自治區', code: '6400' },
        { text: '廣西壯族自治區', code: '4500' },
        { text: '新疆維吾爾自治區', code: '6500' },
        { text: '西藏自治區', code: '5400' },
        { text: '台灣省', code: '0000' },
        { text: '香港特別行政區', code: '4401' },
      ],

      height: '',
      weight: '',
      fat: '',
      // bust: '',
      waist: '',
      hip: '',
      area: '',
      drink: '',
      smoking: '',
      catering: '',
      vegetarian: '',
      sports: '',
      sleep: '',
      diseaseList: [
        { kdis: 'K01', level: '0' },
        { kdis: 'K02', level: '0' },
        { kdis: 'K03', level: '0' },
        { kdis: 'K04', level: '0' },
        { kdis: 'K05', level: '0' },
        { kdis: 'K06', level: '0' },
        { kdis: 'K07', level: '0' },
        { kdis: 'K08', level: '0' },
        { kdis: 'K09', level: '0' },
        { kdis: 'K10', level: '0' },
        { kdis: 'K11', level: '0' },
        { kdis: 'K12', level: '0' },
        { kdis: 'K13', level: '0' },

        // 新增
        { kdis: 'K14', level: '0' },
        { kdis: 'K15', level: '0' },
        { kdis: 'K16', level: '0' },
        { kdis: 'K17', level: '0' },
        { kdis: 'K18', level: '0' },
        { kdis: 'K19', level: '0' },
      ],
      diseaseListAll: [
        { kdis: 'K01', level: '0', name: '高血壓' },
        { kdis: 'K02', level: '0', name: '高血脂' },
        { kdis: 'K03', level: '0', name: '糖尿病' },
        { kdis: 'K04', level: '0', name: '慢性肝病' },
        { kdis: 'K05', level: '0', name: '慢性腎臟病' },
        { kdis: 'K06', level: '0', name: '癌症' },
        { kdis: 'K07', level: '0', name: '肝硬化' },
        { kdis: 'K08', level: '0', name: '慢性阻塞性肺炎' },
        { kdis: 'K09', level: '0', name: '關節炎' },
        { kdis: 'K10', level: '0', name: '心臟病' },
        { kdis: 'K11', level: '0', name: '腦中風' },
        { kdis: 'K12', level: '0', name: '胃腸潰瘍' },
        { kdis: 'K13', level: '0', name: '睡眠障礙' },

        // 新增
        { kdis: 'K14', level: '0', name: '貧血' },
        { kdis: 'K15', level: '0', name: '疼痛' },
        { kdis: 'K16', level: '0', name: '哮喘' },
        { kdis: 'K17', level: '0', name: '痛風' },
        { kdis: 'K18', level: '0', name: '痔瘡' },
        { kdis: 'K19', level: '0', name: '甲狀腺疾病' },
      ],
      disease: [],
      diseaseArr: [],
      diseaseShow: false,
      noSurgery: [],
      noRelativesDisease: [],
      examineList: [
        { ne: 'WBC', ve: '', ut: 'A' },
        { ne: 'HGB', ve: '', ut: 'A' },
        { ne: 'PLT', ve: '', ut: 'A' },
        { ne: 'FG', ve: '', ut: 'B' },
        { ne: 'HBA1C', ve: '', ut: 'A' },
        { ne: 'CHOL', ve: '', ut: 'B' },
        { ne: 'LDLC', ve: '', ut: 'B' },
        { ne: 'HDLC', ve: '', ut: 'B' },
        { ne: 'TG', ve: '', ut: 'B' },
        { ne: 'GOT', ve: '', ut: 'A' },
        { ne: 'GPT', ve: '', ut: 'A' },
        { ne: 'ALP', ve: '', ut: 'A' },
        { ne: 'ALB', ve: '', ut: 'B' },
        { ne: 'CRE', ve: '', ut: 'B' },
        { ne: 'UA', ve: '', ut: 'B' },
        { ne: 'EGFR', ve: '', ut: 'A' },
      ],
      // isBooking: JSON.parse(sessionStorage.getItem("isBooking")) || false,
      isBooking: true,
      bookTime: '',
      bookTime2: '',
      pickerShow: false,
      pickerShow2: false,
      bookingArea: '',
      bookingArea2: '',
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      columns: [],
      currentTime: '',
      currentTime2: '',
      minDate: '',
      maxDate: '',
      phone: '',
      phoneCode: '',
      package: JSON.parse(sessionStorage.getItem('package')) || 1,
      isYY: 0, //1：跳過預約，直接調用接口。0：填寫預約信息 默認為0

      sex: '', //性別 體驗版獨有
      sexSel: false, //是否彈出性別選擇器
      sexList: ['男', '女'],
      birth: '', //生日 體驗版獨有
      currentBirth: new Date('1990/1/1'), //默認顯示時間
      birthSel: false, //是否彈出生日選擇器

      submiting: false,

      stand: false, //測量標準

      isYzzx: false, // 是否為魔鏡月子中心來源

      showPopover: false,
      showPopoverHip: false,
      hasD: false,
      userid: '',
      savedBirth: '',
      minAge: 20,
      maxAge: 90,
    }
  },
  created() {
    this.jumpQuest()

    this.currentIndex = 0
    document.title = '米因健康-問卷信息'

    let minDate = new Date(new Date().setHours(0, 0, 0, 0))
    minDate.setDate(minDate.getDate() + 4)
    //如果是前三天就-3
    minDate.getDate() //得到後三天的時間
    this.minDate = minDate
    this.currentTime = minDate
    this.currentTime2 = minDate
    let maxDate = new Date(new Date().setHours(12, 30, 0, 0))
    maxDate.setDate(maxDate.getDate() + 14)
    //如果是前三天就-3
    maxDate.getDate() //得到後三天的時間
    this.maxDate = maxDate

    var payData = sessionStorage.getItem('payData')
      ? JSON.parse(sessionStorage.getItem('payData'))
      : ''

    let questionData = localStorage.getItem('questionData')
      ? JSON.parse(localStorage.getItem('questionData'))
      : ''
    this.userid = sessionStorage.getItem('userid')
      ? sessionStorage.getItem('userid')
      : ''
    if (questionData && !this.userid) {
      this.sex =
        payData.package == 3
          ? (questionData.sex = '女')
          : questionData.sex == '1'
          ? '男'
          : '女'
      // this.birth = questionData.birth ? questionData.birth : '';
      if (
        questionData.birth &&
        isAgeValid(questionData.birth, this.minAge, this.maxAge)
      ) {
        this.currentBirth = new Date(questionData.birth)
        this.birth = questionData.birth
      } else {
        this.currentBirth = new Date('1990/01/01')
        this.birth = '1990/01/01'
      }
      this.height = questionData.height ? questionData.height : ''
      this.weight = questionData.weight ? questionData.weight : ''
      this.fat = questionData.fat ? questionData.fat : ''
      this.waist = questionData.waist ? questionData.waist : ''
      this.hip = questionData.hip ? questionData.hip : ''
      this.drink = questionData.drink ? questionData.drink + '' : ''
      this.smoking = questionData.smoke ? questionData.smoke + '' : ''
      this.catering = questionData.habeat ? questionData.habeat + '' : ''
      this.vegetarian = questionData.vege ? questionData.vege + '' : ''
      this.sports = questionData.habsport ? questionData.habsport + '' : ''
      this.sleep = questionData.sleep ? questionData.sleep + '' : ''
      // this.disease = questionData.disease
      this.noSurgery = questionData.oper ? questionData.oper : []
      this.noRelativesDisease = questionData.famdis ? questionData.famdis : []
      this.area = questionData.area ? questionData.area : ''

      this.diseaseList =
        questionData.disease && questionData.disease.length > 0
          ? questionData.disease
          : this.diseaseList
      if (questionData.disease && questionData.disease.length > 0) {
        questionData.disease.map((item, index) => {
          if (item.level != 0) {
            this.disease.push(item.kdis)
          }
        })
      }
    }
  },
  mounted() {
    this.swiperHeightChange()
    window.addEventListener('resize', this.swiperHeightChange)
  },
  methods: {
    swiperHeightChange() {
      //微信打開全屏,否則去除頭部
      let clientWidth = document.documentElement.clientWidth
      let clientHeight = document.documentElement.clientHeight
      let ratio = clientWidth / 375

      this.swipeHeight =
        clientHeight -
        (this.$store.state.isWxOpen ? '' : (0.91 * 100 * ratio) / 2)
      // this.swipeHeight = document.documentElement.clientHeight - (this.$store.state.isWxOpen?'':47);
    },
    why() {
      this.markShowError = true
      this.errorTip = `<p>體型是健康的重要指標之一。體型數據的失衡，往往是內在核心生化指標發生重要改變的外在表現，更是慢性病發生的先兆。臨床研究指出，肥胖或過重易引發四高疾病等長期慢性疾病。</p>
							<br>
							<p>我們依據逾2500萬華人的健康大數據，通過與同健康族群者的數據比較，結合與臨牀標準的比較，可為您預測健康風險，並提出健康管理的目標與建議。</p>
							<br>
							<p>因此，請您儘可能準確測量、填寫您的體型信息。</p>`
    },
    onConfirm(value) {
      this.bookingArea = value
      this.showPicker = false
    },
    onConfirm2(value) {
      this.bookingArea2 = value
      this.showPicker2 = false
    },
    onConfirm3(value) {
      this.area = value
      this.showPicker3 = false
    },
    close() {
      this.markShowNull = false
      this.markShowError = false
      this.successShow = false
    },
    onClickLeft() {
      this.markShowNull = true
    },
    backHome() {
      this.$router.push({ path: '/' })
    },
    onChange(index) {
      Toast('當前 Swipe 索引：' + index)
    },
    jumpQuest() {
      this.$nextTick(() => {
        this.$refs.nextSwipe.swipeTo(this.currentIndex, {
          immediate: true,
        })
        // this.currentIndex = 1
        //進度條(原本問卷頁數量寫死,改為檢測頁數數量)
        //this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100;
        this.progressNum =
          ((this.currentIndex + 1) /
            document.querySelectorAll('.van-swipe-item').length) *
          100

        //切換效果
        var target = document.querySelectorAll('.van-swipe-item')[
          this.currentIndex
        ]
        var prevToggle = document.querySelector('.toggle-question')
        prevToggle ? prevToggle.classList.remove('toggle-question') : ''
        target.className += ' toggle-question'
      })
    },
    isGo() {
      this.currentIndex++
      this.jumpQuest()
    },

    wbc(num) {
      if (num > 150 || (num < 0 && num != '')) {
        this.tipsMark()
      }
    },
    hgb(num) {
      if (num > 25 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    plt(num) {
      if (num > 1800 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    fgChange() {
      if (this.examineList[3].ut == 'A') {
        if (
          this.examineList[3].ve > 700 ||
          (this.examineList[3].ve < 30 && this.examineList[3].ve != '')
        ) {
          this.tipsMark()
        }
      } else if (this.examineList[3].ut == 'B') {
        if (
          this.examineList[3].ve > 40 ||
          (this.examineList[3].ve < 1.5 && this.examineList[3].ve != '')
        ) {
          this.tipsMark()
        }
      }
    },
    fg(num) {
      if (this.examineList[3].ut == 'A') {
        if (num > 700 || (num < 30 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[3].ut == 'B') {
        if (num > 40 || (num < 1.5 && num != '')) {
          this.tipsMark()
        }
      }
    },
    hb1ac(num) {
      if (num > 25 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    cholChange() {
      if (this.examineList[5].ut == 'A') {
        if (this.examineList[5].ve > 1100 || this.examineList[5].ve < 50) {
          this.tipsMark()
        }
      } else if (this.examineList[5].ut == 'B') {
        if (this.examineList[5].ve > 28.5 || this.examineList[5].ve < 1.2) {
          this.tipsMark()
        }
      }
    },
    chol(num) {
      if (this.examineList[5].ut == 'A') {
        if (num > 1100 || (num < 50 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[5].ut == 'B') {
        if (num > 28.5 || (num < 1.2 && num != '')) {
          this.tipsMark()
        }
      }
    },
    ldlcChange() {
      if (this.examineList[6].ut == 'A') {
        if (this.examineList[6].ve > 650 || this.examineList[6].ve < 1) {
          this.tipsMark()
        }
      } else if (this.examineList[6].ut == 'B') {
        if (this.examineList[6].ve > 17 || this.examineList[6].ve < 0) {
          this.tipsMark()
        }
      }
    },
    ldlc(num) {
      if (this.examineList[6].ut == 'A') {
        if (num > 650 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[6].ut == 'B') {
        if (num > 17 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    hdlcChange() {
      if (this.examineList[7].ut == 'A') {
        if (this.examineList[7].ve > 250 || this.examineList[7].ve < 1) {
          this.tipsMark()
        }
      } else if (this.examineList[7].ut == 'B') {
        if (this.examineList[7].ve > 7 || this.examineList[7].ve < 0) {
          this.tipsMark()
        }
      }
    },
    hdlc(num) {
      if (this.examineList[7].ut == 'A') {
        if (num > 250 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[7].ut == 'B') {
        if (num > 7 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    tgChange() {
      if (this.examineList[8].ut == 'A') {
        if (this.examineList[8].ve > 9000 || this.examineList[8].ve < 1) {
          this.tipsMark()
        }
      } else if (this.examineList[8].ut == 'B') {
        if (this.examineList[8].ve > 110 || this.examineList[8].ve < 0) {
          this.tipsMark()
        }
      }
    },
    tg(num) {
      if (this.examineList[8].ut == 'A') {
        if (num > 9000 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[8].ut == 'B') {
        if (num > 110 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    got(num) {
      if (num > 2000 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    gpt(num) {
      if (num > 4000 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    alp(num) {
      if (num > 6500 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    albChange() {
      if (this.examineList[12].ut == 'A') {
        if (this.examineList[12].ve > 7 || this.examineList[12].ve < 1) {
          this.tipsMark()
        }
      } else if (this.examineList[12].ut == 'B') {
        if (this.examineList[12].ve > 70 || this.examineList[12].ve < 10) {
          this.tipsMark()
        }
      }
    },
    alb(num) {
      if (this.examineList[12].ut == 'A') {
        if (num > 7 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[12].ut == 'B') {
        if (num > 70 || (num < 10 && num != '')) {
          this.tipsMark()
        }
      }
    },
    creChange() {
      if (this.examineList[13].ut == 'A') {
        if (this.examineList[13].ve > 40 || this.examineList[13].ve < 0) {
          this.tipsMark()
        }
      } else if (this.examineList[13].ut == 'B') {
        if (this.examineList[13].ve > 3600 || this.examineList[13].ve < 0) {
          this.tipsMark()
        }
      }
    },
    cre(num) {
      if (this.examineList[13].ut == 'A') {
        if (num > 40 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[13].ut == 'B') {
        if (num > 3600 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    uaChange() {
      if (this.examineList[14].ut == 'A') {
        if (this.examineList[14].ve > 20 || this.examineList[14].ve < 0) {
          this.tipsMark()
        }
      } else if (this.examineList[14].ut == 'B') {
        if (this.examineList[14].ve > 1200 || this.examineList[14].ve < 0) {
          this.tipsMark()
        }
      }
    },
    ua(num) {
      if (this.examineList[14].ut == 'A') {
        if (num > 20 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[14].ut == 'B') {
        if (num > 1200 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    egfr(num) {
      if (num > 300 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },

    nextQuest(isGo) {
      //頂頭開始
      window.scrollTo(0, 0)

      if (isGo != 15) {
        this.pageIndex = isGo + 2
      }

      let _that = this
      if (isGo === 0) {
        //體驗版獨有
        if (this.sex == '') {
          this.markShowError = true
          this.errorTip = '請選擇性別'
        } else if (this.birth == '') {
          this.markShowError = true
          this.errorTip = '請選擇您的出生日期'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            sex: this.sex == '男' ? '1' : '2',
            birth: this.birth,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 1) {
        if (this.height == '' || this.height < 130 || this.height > 200) {
          this.markShowError = true
          this.errorTip = '請輸入合適身高，建議範圍：130~200'
        } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
          this.markShowError = true
          this.errorTip = '請輸入合適體重，建議範圍：30~160'
        } else if ((this.fat != '' && this.fat < 3) || this.fat > 75) {
          this.markShowError = true
          this.errorTip = '請輸入合適體脂率，建議範圍：3~75'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            height: this.height,
            weight: this.weight,
            fat: this.fat,
          }
          // console.log(data)
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 2) {
        if (this.waist == '' || this.waist < 40 || this.waist > 150) {
          this.markShowError = true
          this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
        } else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
          this.markShowError = true
          this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            waist: this.waist,
            hip: this.hip,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 3) {
        if (this.area == '' || this.area == '' || typeof area == 'number') {
          this.markShowError = true
          this.errorTip = '請選擇您的居住地'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            area: this.area,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 4) {
        this.isGo()
      } else if (isGo === 5) {
        this.isGo()
      } else if (isGo === 6) {
        if (this.drink == '') {
          this.markShowError = true
          this.errorTip = '請選擇您是否喝酒'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            drink: this.drink,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 7) {
        if (this.smoking == '') {
          this.markShowError = true
          this.errorTip = '請選擇您是否吸煙'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            smoke: this.smoking,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 8) {
        if (this.catering == '') {
          this.markShowError = true
          this.errorTip = '請選擇您的用餐習慣'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            habeat: this.catering,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 9) {
        if (this.vegetarian == '') {
          this.markShowError = true
          this.errorTip = '請選擇您是否是全日素食者'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            vege: this.vegetarian,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 10) {
        if (this.sports == '') {
          this.markShowError = true
          this.errorTip = '請選擇您的運動習慣'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            habsport: this.sports,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 11) {
        if (this.sleep == '') {
          this.markShowError = true
          this.errorTip = '請選擇您是否有睡眠障礙'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            sleep: this.sleep,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 12) {
        if (this.disease.length > 0) {
          this.hasD = true
          this.diseaseShow = true
        } else {
          this.hasD = false
          this.pageIndex++
          this.diseaseShow = false
        }
        let questionData = localStorage.getItem('questionData')
          ? JSON.parse(localStorage.getItem('questionData'))
          : {}
        let data = {
          ...questionData,
          disease: this.diseaseList,
        }
        localStorage.setItem('questionData', JSON.stringify(data))
        // return ;
        this.isGo()
      } else if (isGo === 13) {
        let _that = this

        try {
          this.diseaseArr.forEach(function(item, index) {
            if (item.level == '0') {
              _that.markShowError = true
              _that.errorTip = '請選擇' + item.name + '服藥情況!'
              throw new Error('EndIterative')
            } else {
              _that.diseaseList.forEach(function(itemL, number) {
                if (item.kdis == itemL.kdis) {
                  _that.diseaseList[number].level = item.level
                }
              })
            }
          })

          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            disease: _that.diseaseList,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          _that.isGo()
        } catch (e) {
          console.log('END')
        }
      } else if (isGo === 14) {
        let questionData = localStorage.getItem('questionData')
          ? JSON.parse(localStorage.getItem('questionData'))
          : {}
        let data = {
          ...questionData,
          oper: this.noSurgery,
        }
        localStorage.setItem('questionData', JSON.stringify(data))
        this.isGo()
      } else if (isGo === 15) {
        let questionData = localStorage.getItem('questionData')
          ? JSON.parse(localStorage.getItem('questionData'))
          : {}
        let data = {
          ...questionData,
          famdis: this.noRelativesDisease,
        }
        localStorage.setItem('questionData', JSON.stringify(data))
        this.handleToPay()
      }
    },
    handleToPay() {
      let data = {
        sex: this.sex == '男' ? 1 : this.sex == '女' ? 2 : '',
        birth: this.birth.replace(/[^\d]/g, ''),
        height: this.height,
        weight: this.weight,
        fat: this.fat,
        waist: this.waist,
        hip: this.hip,
        area: this.area.code,
        drink: this.drink,
        smoke: this.smoking,
        habeat: this.catering,
        vege: this.vegetarian,
        habsport: this.sports,
        sleep: this.sleep,
        disease: this.diseaseList,
        oper: this.noSurgery,
        famdis: this.noRelativesDisease,
      }
      data.code = 'FTE'
      submitReportHealth(data).then((res) => {
        this.submiting = false
        if (res.code == 200) {
          const result = JSON.parse(Base64.decode(res.data))
          this.$router.replace(`/loading/jkzc/${result.id}`)
        } else {
          Toast.fail(res.msg)
        }
      })
    },
    prevQuest() {
      this.currentIndex--
      if (this.hasD) {
        this.pageIndex--
      } else if (!this.hasD && this.pageIndex == 15) {
        this.pageIndex = this.pageIndex - 2
      } else {
        this.pageIndex--
      }
      this.jumpQuest()
    },
    tipsMark() {
      this.markShowError = true
      this.errorTip =
        '<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>'
    },
    confirm() {
      if (new Date(this.currentTime).getDay() == '0') {
        Toast.fail('請選擇週日以外的日期!')
      } else {
        this.pickerShow = false
        let dateFormat = new Date(this.currentTime).getTime()
        this.bookTime = new Date(dateFormat)
          .toLocaleString()
          .replace(/:\d{1,2}$/, ' ')
      }
    },
    confirm2() {
      if (new Date(this.currentTime2).getDay() == '0') {
        Toast.fail('請選擇週日以外的日期!')
      } else {
        this.pickerShow2 = false
        let dateFormat = new Date(this.currentTime2).getTime()
        this.bookTime2 = new Date(dateFormat)
          .toLocaleString()
          .replace(/:\d{1,2}$/, ' ')
      }
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 15 === 0)
      }
      if (type === 'hour') {
        return options.filter((option) => option < 13 && option > 8)
      }
      return options
    },

    //體驗版獨有
    //性別選擇
    sexConfirm(value) {
      this.sex = value
      this.sexSel = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    //生日選擇
    birthConfirm(value) {
      var language = navigator.language
      if (language.toLocaleLowerCase().indexOf('zh-cn') >= 0) {
        this.birth = dayjs(value).format('YYYY/MM/DD')
      } else {
        this.birth = dayjs(value).format('YYYY/MM/DD')
      }
      this.savedBirth = dayjs(value).format('YYYY/MM/DD')

      this.birthSel = false
    },
    limitDate(year) {
      //參數：距今多少年
      let now = new Date()
      return new Date(
        now.getFullYear() -
          year +
          '/' +
          (now.getMonth() + 1) +
          '/' +
          now.getDate()
      )
    },
  },
  computed: {
    //年齡為20-90歲之間(直接綁定在組件上會卡死)
    birthMinDate() {
      return this.limitDate(this.maxAge)
    },
    birthMaxDate() {
      return this.limitDate(this.minAge)
    },
  },
  watch: {
    disease(newVal, oldVal) {
      if (newVal.length > 0) {
        let _that = this
        _that.diseaseArr = []
        this.disease.forEach(function(item, index) {
          _that.diseaseListAll.forEach(function(itemL, number) {
            if (item == itemL.kdis) {
              _that.diseaseArr.push(_that.diseaseListAll[number])
            }
          })
        })
        _that.diseaseList.forEach(function(item, index) {
          _that.diseaseArr.forEach(function(itemL, number) {
            if (item.kdis == itemL.kdis) {
              itemL.level = item.level
            }
          })
        })
        this.questionNum = 16
      } else {
        this.diseaseList = [
          { kdis: 'K01', level: '0' },
          { kdis: 'K02', level: '0' },
          { kdis: 'K03', level: '0' },
          { kdis: 'K04', level: '0' },
          { kdis: 'K05', level: '0' },
          { kdis: 'K06', level: '0' },
          { kdis: 'K07', level: '0' },
          { kdis: 'K08', level: '0' },
          { kdis: 'K09', level: '0' },
          { kdis: 'K10', level: '0' },
          { kdis: 'K11', level: '0' },
          { kdis: 'K12', level: '0' },
          { kdis: 'K13', level: '0' },
          { kdis: 'K14', level: '0' },
          { kdis: 'K15', level: '0' },
          { kdis: 'K16', level: '0' },
          { kdis: 'K17', level: '0' },
          { kdis: 'K18', level: '0' },
          { kdis: 'K19', level: '0' },
        ]
      }
    },
  },
  destoryed() {
    window.removeEventListener('resize', this.swiperHeightChange)
  },
}
