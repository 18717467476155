import { render, staticRenderFns } from "./questionJkzc.vue?vue&type=template&id=f0b0b524&scoped=true"
import script from "./questionJkzc.vue?vue&type=script&lang=js"
export * from "./questionJkzc.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/questionJKZC.css?vue&type=style&index=0&id=f0b0b524&prod&scoped=true&lang=css&external"
import style1 from "./questionJkzc.vue?vue&type=style&index=1&id=f0b0b524&prod&scoped=true&lang=css"
import style2 from "./questionJkzc.vue?vue&type=style&index=2&id=f0b0b524&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b0b524",
  null
  
)

export default component.exports