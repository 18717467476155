<template>
  <div class="content">
    <van-nav-bar title="米因健康-問卷信息" left-arrow @click-left="onClickLeft" :fixed="true" />
    <van-progress color="#6681FA" :percentage="progressNum" :show-pivot="false" pivot-text="" stroke-width="4"
      track-color="#DCDEE5" class="margin-t" />
    <div class="page_box">
      <span>{{ pageIndex }}</span>/16
    </div>
    <van-swipe :loop="false" :show-indicators="true" :touchable="false" ref="nextSwipe" :initial-swipe="currentIndex"
      :height="swipeHeight">
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你好，請告訴我你的性別和出生日期？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="sex group">
                <span>性別</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field readonly clickable :value="sex" placeholder="請選擇" @click="sexSel = true" />
                </div>
              </div>
              <div class="group">
                <span>出生日期</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field readonly clickable :value="birth" placeholder="請選擇" @click="birthSel = true" />
                </div>
              </div>
            </div>
            <div class="nextQues" @click="nextQuest(0)">下一步</div>
            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                該健康AI評估僅適用於年齡在20-90週歲之間的人群
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你好，請告訴我你的身高、體重和體脂率？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <span>身高</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="height" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>體重</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="weight" placeholder="請填寫" type="number" />
                    <div class="unit"><span>kg</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>體脂率</span><span style="color: #A1A7B2;">(選填)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="fat" placeholder="請填寫(選填)" type="number" />
                    <div class="unit"><span>%</span></div>
                  </label>
                </div>
              </div>
              <!-- <div class="group fitText">
                                若你不知道自身的體脂率信息，米因大數據會為你進行推算。
                                若你屬於健身愛好者，運動員等體脂率較為特殊的人群，請你務必填寫準確的體脂率數值。
                            </div> -->
            </div>
            <!-- 返回上一頁，體驗版出現(體驗版會多出一頁) -->
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(1)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                體脂率不清楚的可選擇不填哦！ 大數據引擎會根據你的情況幫你推算。 除非特殊體型，比如常做健身的肌肉比例比普通人高很多，普通人的體脂率可以通過大數據估算出來。
                <br>若你屬於健身愛好者，運動員等體脂率較為特殊的人群，建議你前往合資格體檢或健身中心測量你的體脂率，再做一次健康AI評估。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請告訴我你的腰臀圍信息？請你儘可能準確測量、填寫你的體型信息。 <span @click="why" class="qjc-c-primary">為什麼？</span> <span
                  @click="stand = true" class="qjc-c-primary">測量標準</span>
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <!-- <div class="group">
                                <span>胸圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="bust" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div> -->
              <div class="group">
                <div class="group_title_cell">
                  <span>腰圍</span>
                  <span class="cankao" @click="showPopover = true">參考腰圍</span>
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="waist" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <div class="group_title_cell">
                  <span>臀圍</span>
                  <span class="cankao" @click="showPopoverHip = true">參考臀圍</span>
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="hip" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="prevQues waist_wrap" @click="prevQuest">上一步</div>
            <div class="nextQues waist_wrap" @click="nextQuest(2)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                隨年齡增長，BMI與四高、長期慢病及重症，有非常緊密的聯繫。而四高風險對個人免疫力有非常大的影響，所以，要降低四高風險，首先應加強管理體型、體重。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請選擇你的居住地？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <div class="groupLabel selArea">
                  <div class="arrow"></div>
                  <van-field readonly clickable :value="area.text" placeholder="請選擇" @click="showPicker3 = true" />
                </div>
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(3)">下一步</div>
          </div>

        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你是否喝酒？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="drink">
                <van-radio checked-color="#6883FB" name="1">不喝</van-radio>
                <van-radio checked-color="#6883FB" name="2">天天喝</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常喝</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾喝</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(6)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                喝酒習慣：指的是所有含酒精飲品或食品。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你是否吸煙？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="smoking">
                <van-radio checked-color="#6883FB" name="1">不吸</van-radio>
                <van-radio checked-color="#6883FB" name="2">天天吸</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常吸</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾吸</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(7)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                吸煙習慣：指的是所有傳統香煙、雪茄、水煙和所有電子煙產品。18個月內，即使只吸一支煙的，也算作“偶爾吸煙”。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你的用餐習慣？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="catering">
                <van-radio checked-color="#6883FB" name="1">定時定量</van-radio>
                <van-radio checked-color="#6883FB" name="2">不定時不定量</van-radio>
                <van-radio checked-color="#6883FB" name="3">定時但不定量</van-radio>
                <van-radio checked-color="#6883FB" name="4">不定時但定量</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(8)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你是否是全日素食者？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="vegetarian">
                <van-radio checked-color="#6883FB" name="1">不是</van-radio>
                <van-radio checked-color="#6883FB" name="2">是</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(9)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你的運動習慣？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="sports">
                <van-radio checked-color="#6883FB" name="1">沒有或每週少於1小時</van-radio>
                <van-radio checked-color="#6883FB" name="2">每週運動1～2小時</van-radio>
                <van-radio checked-color="#6883FB" name="3">每週運動3～4小時</van-radio>
                <van-radio checked-color="#6883FB" name="4">每週運動5～6小時</van-radio>
                <van-radio checked-color="#6883FB" name="5">每週運動7小時以上</van-radio>
              </van-radio-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(10)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                問卷中的運動習慣時間：連續運動半小時以上才能定義為運動中（做家務不算運動哦!），運動類型和強度根據年齡和身體狀況而定。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox ">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你是否有睡眠障礙？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-radio-group v-model="sleep">
                <van-radio checked-color="#6883FB" name="1">沒有</van-radio>
                <van-radio checked-color="#6883FB" name="2">非常嚴重</van-radio>
                <van-radio checked-color="#6883FB" name="3">經常</van-radio>
                <van-radio checked-color="#6883FB" name="4">偶爾</van-radio>
              </van-radio-group>
              <div class="group fitText">
                睡眠障礙的表現包括：睡眠時間過長或過短，如入睡困難、淺睡、易醒、失眠等；睡眠過程中出現異常行為，如夢遊、夢囈、夜驚、磨牙等
              </div>
            </div>
            <div class="prevQues sleep_wrap" @click="prevQuest">上一步</div>
            <div class="nextQues sleep_wrap" @click="nextQuest(11)">下一步</div>
            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                問卷中的睡眠障礙的指標：
                需要服用藥物幫助才能正常入睡的，屬於睡眠障礙。不需要服用藥物幫助睡眠的，請選擇：沒有；需嚴重藥物幫助睡眠的，請選擇：非常嚴重；經常服用藥物幫助睡眠的，請選擇：經常； 偶爾服用藥物幫助睡眠的，請選擇：偶爾。
              </div>
              <div class="clear"></div>
            </div>

            <!-- <div class="question1 new_question">
						    <div class="avatar"></div>
						    <div class="quesText">
								<img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
						         長期失眠得不到改善，將造成內分泌失調，導致免疫力下降。若失眠狀況在一週內超過三天，並且持續兩到三週以上，建議及早就醫。
						    </div>
						    <div class="clear"></div>
						</div> -->


          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你患有過下列疾病嗎？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"下一步"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="disease" checked-color="#6883FB">
                <van-checkbox checked-color="#6883FB" shape="square" v-for="(disItem, index) in diseaseListAll"
                  :name="disItem.kdis" :key="index">{{ disItem.name }}
                </van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(12)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                問卷中的患病指的是：
                醫生經過對病人疾病的病因、發病機制作出分類鑑別，最後確診的疾病。
                換句話説，自己覺得感覺不舒服但是沒有正規醫生診斷的，不能算作“患病”。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item v-if="diseaseShow">
        <div class="swipeBox medicine">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請選擇患病的用藥情況 (每種疾病請選擇一種用藥情況)
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">

              <table id="selTable" cellspacing="0">
                <thead>
                  <tr>
                    <td>疾病名稱</td>
                    <td>經常服藥</td>
                    <td>偶爾服藥</td>
                    <td>不服藥</td>
                  </tr>
                  <tr v-for="(disItemA, indexO) in diseaseArr" :key="indexO">
                    <td>{{ disItemA.name }}</td>
                    <td><label><input type="radio" v-model="disItemA.level" :name="disItemA.kdis"
                          value="1"><span></span></label></td>
                    <td><label><input type="radio" v-model="disItemA.level" :name="disItemA.kdis"
                          value="2"><span></span></label></td>
                    <td><label><input type="radio" v-model="disItemA.level" :name="disItemA.kdis"
                          value="3"><span></span></label></td>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(13)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你是否做過下列手術？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"下一步"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="noSurgery">
                <van-checkbox checked-color="#6883FB" shape="square" name="1">頭或腦部</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="2">眼鼻耳</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="3">口腔、食道</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="4">胸部(心、肺等)</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="5">腹部(胃、肝、膽、胰、腎等)</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="6">關節與骨</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="7">婦科手術</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="8">泌尿系統</van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(14)">下一步</div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你的直系親屬有下列疾病嗎？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"選好了"即可。
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <van-checkbox-group v-model="noRelativesDisease">
                <van-checkbox checked-color="#6883FB" shape="square" name="1">癌症</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="2">腦中風</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="3">心肌梗塞</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="4">肝硬化</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="5">老年失智</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="6">慢性阻塞性肺炎</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="7">結核病</van-checkbox>
                <van-checkbox checked-color="#6883FB" shape="square" name="8">先天畸形</van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div v-if="!submiting" class="nextQues" @click="nextQuest(15)">選好了，提交</div>
            <div v-else class="nextQues"><span>正在提交</span> <van-loading type="spinner" color="#6681FA" /></div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                問卷中的直系親屬包括：
                父母、子女、祖父母、外祖父母，兄弟姐妹、子女、孫子女等。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <!-- 性別選擇 生日選擇 體驗版獨有 -->
    <van-popup v-model="sexSel" position="bottom">
      <van-picker :show-toolbar="true" :columns="sexList" @cancel="sexSel = false" @confirm="sexConfirm"
        class="sexPicker" />
    </van-popup>
    <van-popup v-model="birthSel" position="bottom">
      <van-datetime-picker type="date" :formatter="formatter" v-model="currentBirth" :min-date="birthMinDate"
        :max-date="birthMaxDate" @cancel="birthSel = false" @confirm="birthConfirm" class="birthPicker" />
    </van-popup>

    <van-popup v-model="pickerShow" position="bottom">
      <div class="dateName">
        <ul>
          <li>年</li>
          <li>月</li>
          <li>日</li>
          <li>時</li>
          <li>分</li>
        </ul>
      </div>
      <van-datetime-picker v-model="currentTime" type="datetime" :filter="filter" :min-date="minDate"
        :max-date="maxDate" :min-hour="9" :max-hour="12" @cancel="pickerShow = false" @confirm="confirm" />
    </van-popup>
    <van-popup v-model="pickerShow2" position="bottom">
      <div class="dateName">
        <ul>
          <li>年</li>
          <li>月</li>
          <li>日</li>
          <li>時</li>
          <li>分</li>
        </ul>
      </div>
      <van-datetime-picker v-model="currentTime2" type="datetime" :filter="filter" :min-date="minDate"
        :max-date="maxDate" :min-hour="9" :max-hour="12" @cancel="pickerShow2 = false" @confirm="confirm2" />
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker2 = false" confirm-button-text="確定"
        cancel-button-text="取消" @confirm="onConfirm2" />
    </van-popup>
    <van-popup v-model="showPicker3" position="bottom">
      <van-picker show-toolbar :columns="areaList" @cancel="showPicker3 = false" @confirm="onConfirm3" />
    </van-popup>

    <!-- 測量標準 -->
    <van-popup v-model="stand" class="stand">
      <van-image width="4.36rem" height="4.37rem"
        :src="require('@/assets/images/questions_measureStand.png')"></van-image>
      <ul class="qjc-c-dark qjc-fts-28 qjc-texta-l">
        <li>
          胸圍：平靜呼吸時，經乳頭點的胸部水平圍長。
        </li>
        <li>
          腰圍：經臍部中心的水平圍長，或肋骨以下、胯骨以上的最小水平圍長，在呼氣結束、吸氣未開始時測量。
        </li>
        <li>
          臀圍：臀部向後最突出部位的水平圍長。
        </li>
      </ul>
      <!-- 關閉按鈕 -->
      <van-image width="0.8rem" height="0.8rem" class="close-btn" @click="stand = false"
        :src="require('@/assets/images/givingMasks_close.png')"></van-image>
    </van-popup>
    <div class="mark" v-show="markShowNull">
      <div>
        <div class="markBox">
          <img src="@/assets/images/errorTip.png" alt="">
          <div class="tipText">
            你尚有未完成的健康AI評估，是否繼續完成？
          </div>
          <div class="btnGroup">
            <div class="btnEsc" @click="backHome">返回首頁</div>
            <div class="btnEnt" @click="close">繼續完成</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>
    <div class="mark" v-show="markShowError">
      <div>
        <div class="markBoxError">
          <div class="markBoxErrorImg">
            <img src="@/assets/images/tips.png" alt="">
          </div>
          <div class="tipText2 tipText3" v-html="errorTip">

          </div>
          <div class="btnGroup">
            <div class="btnEnt2 qjc-fts-32" @click="close">我知道了</div>
          </div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>

    <div class="mark" v-show="successShow">
      <div>
        <div class="markBox">
          <img class="success_icon" src="@/assets/images/success_icon.png" alt="">
          <div class="tipText success_text">
            <p>問卷信息已核實，確認提交問卷信息。</p>
            <p>問卷信息會自動保存(最多可創建10個)，你可以在下次填寫時調取。</p>
            <p>請在下方填寫該健康AI評估信息的用户名與描述，方便你進行記憶與管理。</p>
          </div>
          <div class="name_wrap">
            <van-field class="name_input" v-model="username" placeholder="用户名" type="text" />
          </div>
          <div class="btnGroup success_pop">
            <div class="btnEnt" @click="handleToPay">確認</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>

    <van-popup class="wait_popup" v-model="showPopover">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">78</div>
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">88</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">98</div>
              <div class="tab_cell_item">100</div>
              <div class="tab_cell_item">104</div>
              <div class="tab_cell_item">106</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">60</div>
              <div class="tab_cell_item">64</div>
              <div class="tab_cell_item">68</div>
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">77</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup class="wait_popup" v-model="showPopoverHip">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">99</div>
              <div class="tab_cell_item">101</div>
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">105</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">109</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">111</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">116</div>
              <div class="tab_cell_item">119</div>
              <div class="tab_cell_item">121</div>
              <div class="tab_cell_item">125</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">110</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">120</div>
              <div class="tab_cell_item">127</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import questionJKZC from '@/js/questionJKZC';
import Vue from 'vue';
import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  Loading
} from 'vant';

Vue.use(NavBar).use(Progress).use(Swipe).use(SwipeItem).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup).use(Field).use(Picker).use(Popup).use(Area).use(DatetimePicker).use(Cell).use(CellGroup).use(Loading);
export default {
  ...questionJKZC
}
</script>

<style src="@/assets/css/questionJKZC.css" scoped></style>
<style scoped>
.content>>>.van-icon-arrow-left:before {
  color: #40444D;
}

.content>>>.van-nav-bar__content {
  height: 46px;
}

.content>>>.van-nav-bar__title {
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.content>>>.margin-t {
  margin-top: 46px;
}

.content>>>.van-radio {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
}

.content>>>.van-radio:last-child {
  border: none;
}

.content>>>.van-checkbox {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
}

.content>>>.van-checkbox__icon .van-icon {
  border-radius: 4px;
}

.content>>>.van-swipe__indicators {
  bottom: -12px;
}

.content>>>.van-cell {
  padding: 0;
  float: left;
  width: 100%;
  height: 0.7rem;
}

.content>>>.van-cell input {
  float: left;
  font-size: 0.32rem;
  height: 0.7rem;
  line-height: 0.7rem;
}

.content>>>.van-cell input::placeholder {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 300;
  color: rgba(161, 167, 178, 1);
}

.selArea>>>.van-cell {
  width: 100% !important;
}

/* 體驗版獨有 */
.sexPicker>>>.van-picker__cancel,
.sexPicker>>>.van-picker__confirm,
.birthPicker>>>.van-picker__cancel,
.birthPicker>>>.van-picker__confirm {
  color: #6681FA;
}


.new_question {
  margin: 0rem 0.24rem 1.48rem 0.24rem;
}

.new_question .quesText {
  font-size: 0.28rem;
  line-height: 0.48rem;
  min-width: 5.7rem !important;
}

.new_question .question_tips_icon {
  width: 1.06rem;
  height: 0.32rem;
}

.waist_wrap {
  margin-bottom: 0.6rem;
}

.sleep_wrap {
  margin-bottom: 0.6rem;
}
</style>

<style lang="scss" scoped>
// 正在提交loading
.van-loading {
  display: inline-block;
  line-height: 0.32rem;
  vertical-align: super;

  .van-loading__spinner {
    width: 0.32rem;
    height: 0.32rem;
  }
}

//測量標準
.van-popup {
  &.stand {
    width: 5.88rem;
    height: 8.89rem;
    padding-top: 0.72rem;
    border-radius: 0.04rem;
    overflow: visible;

    ul {
      margin-top: 0.48rem;

      li {
        position: relative;
        line-height: 0.4rem;
        margin: 0 0.26rem 0.26rem 0.58rem;

        &::before {
          position: absolute;
          top: 0.05rem;
          left: -0.37rem;
          width: 0.28rem;
          height: 0.28rem;
          line-height: 0.28rem;
          border: 0.01rem solid #40444D;
          border-radius: 50%;
          text-align: center;
          font-size: 0.2rem;
        }

        &:first-child::before {
          content: '1';
        }

        &:nth-child(2)::before {
          content: '2';
        }

        &:nth-child(3)::before {
          content: '3';
        }
      }
    }

    .close-btn {
      position: absolute;
      left: 50%;
      bottom: -1.19rem;
      transform: translateX(-50%);
    }
  }
}


.success_pop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem !important;
}

.success_icon {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.name_wrap {
  width: 100%;
  height: 0.84rem;
  padding: 0 0.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.name_input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.84rem !important;
  line-height: 0.84rem !important;
  padding: 0 0.3rem !important;
  border: 0.01rem solid #DCDFE6;
  border-radius: 0.08rem;
}

.success_text {
  height: auto;
  padding: 0 0.4rem;
  text-align: left;
  width: 100%;
}

.group_title_cell {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cankao {
    color: rgba(140, 145, 154, 1);
    font-family: PingFang SC;
    font-size: 0.28rem;
  }
}

.wait_popup {
  border-radius: 0.12rem;
}

.size_chart {
  width: 6rem;
  height: 3.6rem;
  text-align: center;
  padding: 0.3rem;
  border-radius: 0.12rem;

  .size_chart_title {
    font-size: 0.28rem;
    font-weight: 500;
    font-family: SF Pro Text;
    color: rgb(50, 54, 62);
  }

  .size_chart_tab {
    margin-top: 0.16rem;
  }

  .size_chart_tab_cell {
    display: flex;
    align-items: center;

    .tab_cell_item_list {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .tab_cell_item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.6rem;
      height: 0.48rem;
      // padding: 0 0.10rem;
      border-top: 0.01rem solid rgb(215, 219, 225);
      border-bottom: 0.01rem solid rgb(215, 219, 225);
      border-right: 0.01rem solid rgb(215, 219, 225);
      box-sizing: border-box;
    }

    .the {
      display: flex;
      align-items: center;
      width: 1.6rem;
      text-align: left;
      height: 0.48rem;
      padding: 0 0.10rem;
      border: 0.01rem solid rgb(215, 219, 225);
    }

    .no_bottom {
      border-bottom: none !important;
    }
  }
}

.page_box {
  position: absolute;
  top: 56px;
  right: 0.18rem;
  width: 1.2rem;
  font-size: 0.3rem;
  padding: 0.08rem 0.12rem;
  border: 0.01rem solid rgba(102, 129, 250, 1);
  border-radius: 0.4rem;
  z-index: 999;
  font-weight: 500;

  span {
    font-size: 0.36rem;
    color: rgba(102, 129, 250, 1);
  }

}
</style>